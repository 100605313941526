import React from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";

import AboutUsContent from "../components/AboutUsContent/AboutUsContent";

// markup
const IndexPage = () => {

	return (
		<Layout>
			<Seo title="Om oss - Putso" />
			<AboutUsContent />
		</Layout>
	);
};

export default IndexPage;
